export default [{
 n: 'Andorra', c: '+376', f: '🇦🇩', cc: 'AD',
}, {
 n: 'Afghanistan', c: '+93', f: '🇦🇫', cc: 'AF',
}, {
 n: 'Antigua and Barbuda', c: '+1268', f: '🇦🇬', cc: 'AG',
}, {
 n: 'Anguilla', c: '+1264', f: '🇦🇮', cc: 'AI',
}, {
 n: 'Albania', c: '+355', f: '🇦🇱', cc: 'AL',
}, {
 n: 'Armenia', c: '+374', f: '🇦🇲', cc: 'AM',
}, {
 n: 'Angola', c: '+244', f: '🇦🇴', cc: 'AO',
}, {
 n: 'Antarctica', c: '+672', f: '🇦🇶', cc: 'AQ',
}, {
 n: 'Argentina', c: '+54', f: '🇦🇷', cc: 'AR',
}, {
 n: 'American Samoa', c: '+1684', f: '🇦🇸', cc: 'AS',
}, {
 n: 'Austria', c: '+43', f: '🇦🇹', cc: 'AT',
}, {
 n: 'Australia', c: '+61', f: '🇦🇺', cc: 'AU',
}, {
 n: 'Aruba', c: '+297', f: '🇦🇼', cc: 'AW',
}, {
 n: 'Åland Islands', c: '+358', f: '🇦🇽', cc: 'AX',
}, {
 n: 'Azerbaijan', c: '+994', f: '🇦🇿', cc: 'AZ',
}, {
 n: 'Bosnia and Herzegovina', c: '+387', f: '🇧🇦', cc: 'BA',
}, {
 n: 'Barbados', c: '+1246', f: '🇧🇧', cc: 'BB',
}, {
 n: 'Bangladesh', c: '+880', f: '🇧🇩', cc: 'BD',
}, {
 n: 'Belgium', c: '+32', f: '🇧🇪', cc: 'BE',
}, {
 n: 'Burkina Faso', c: '+226', f: '🇧🇫', cc: 'BF',
}, {
 n: 'Bulgaria', c: '+359', f: '🇧🇬', cc: 'BG',
}, {
 n: 'Bahrain', c: '+973', f: '🇧🇭', cc: 'BH',
}, {
 n: 'Burundi', c: '+257', f: '🇧🇮', cc: 'BI',
}, {
 n: 'Benin', c: '+229', f: '🇧🇯', cc: 'BJ',
}, {
 n: 'Saint Barthélemy', c: '+590', f: '🇧🇱', cc: 'BL',
}, {
 n: 'Bermuda', c: '+1441', f: '🇧🇲', cc: 'BM',
}, {
 n: 'Brunei Darussalam', c: '+673', f: '🇧🇳', cc: 'BN',
}, {
 n: 'Bolivia (Plurinational State of)', c: '+591', f: '🇧🇴', cc: 'BO',
}, {
 n: 'Bonaire, Sint Eustatius and Saba', c: '+5997', f: '🇧🇶', cc: 'BQ',
}, {
 n: 'Brazil', c: '+55', f: '🇧🇷', cc: 'BR',
}, {
 n: 'Bhutan', c: '+975', f: '🇧🇹', cc: 'BT',
}, {
 n: 'Bouvet Island', c: '+47', f: '🇧🇻', cc: 'BV',
}, {
 n: 'Botswana', c: '+267', f: '🇧🇼', cc: 'BW',
}, {
 n: 'Belarus', c: '+375', f: '🇧🇾', cc: 'BY',
}, {
 n: 'Belize', c: '+501', f: '🇧🇿', cc: 'BZ',
}, {
 n: 'Canada', c: '+1', f: '🇨🇦', cc: 'CA',
}, {
 n: 'Switzerland', c: '+41', f: '🇨🇭', cc: 'CH',
}, {
 n: 'Côte d\'Ivoire', c: '+225', f: '🇨🇮', cc: 'CI',
}, {
 n: 'Chile', c: '+56', f: '🇨🇱', cc: 'CL',
}, {
 n: 'Cameroon', c: '+237', f: '🇨🇲', cc: 'CM',
}, {
 n: 'China', c: '+86', f: '🇨🇳', cc: 'CN',
}, {
 n: 'Colombia', c: '+57', f: '🇨🇴', cc: 'CO',
}, {
 n: 'Costa Rica', c: '+506', f: '🇨🇷', cc: 'CR',
}, {
 n: 'Cuba', c: '+53', f: '🇨🇺', cc: 'CU',
}, {
 n: 'Cabo Verde', c: '+238', f: '🇨🇻', cc: 'CV',
}, {
 n: 'Curaçao', c: '+599', f: '🇨🇼', cc: 'CW',
}, {
 n: 'Christmas Island', c: '+61', f: '🇨🇽', cc: 'CX',
}, {
 n: 'Cyprus', c: '+357', f: '🇨🇾', cc: 'CY',
}, {
 n: 'Germany', c: '+49', f: '🇩🇪', cc: 'DE',
}, {
 n: 'Djibouti', c: '+253', f: '🇩🇯', cc: 'DJ',
}, {
 n: 'Denmark', c: '+45', f: '🇩🇰', cc: 'DK',
}, {
 n: 'Dominica', c: '+767', f: '🇩🇲', cc: 'DM',
}, {
 n: 'Algeria', c: '+213', f: '🇩🇿', cc: 'DZ',
}, {
 n: 'Ecuador', c: '+593', f: '🇪🇨', cc: 'EC',
}, {
 n: 'Estonia', c: '+372', f: '🇪🇪', cc: 'EE',
}, {
 n: 'Egypt', c: '+20', f: '🇪🇬', cc: 'EG',
}, {
 n: 'Western Sahara', c: '+212', f: '🇪🇭', cc: 'EH',
}, {
 n: 'Eritrea', c: '+291', f: '🇪🇷', cc: 'ER',
}, {
 n: 'Spain', c: '+34', f: '🇪🇸', cc: 'ES',
}, {
 n: 'Ethiopia', c: '+251', f: '🇪🇹', cc: 'ET',
}, {
 n: 'Finland', c: '+358', f: '🇫🇮', cc: 'FI',
}, {
 n: 'Fiji', c: '+679', f: '🇫🇯', cc: 'FJ',
}, {
 n: 'Micronesia (Federated States of)', c: '+691', f: '🇫🇲', cc: 'FM',
}, {
 n: 'France', c: '+33', f: '🇫🇷', cc: 'FR',
}, {
 n: 'Gabon', c: '+241', f: '🇬🇦', cc: 'GA',
}, {
 n: 'Grenada', c: '+1473', f: '🇬🇩', cc: 'GD',
}, {
 n: 'Georgia', c: '+995', f: '🇬🇪', cc: 'GE',
}, {
 n: 'French Guiana', c: '+594', f: '🇬🇫', cc: 'GF',
}, {
 n: 'Guernsey', c: '+44', f: '🇬🇬', cc: 'GG',
}, {
 n: 'Ghana', c: '+233', f: '🇬🇭', cc: 'GH',
}, {
 n: 'Gibraltar', c: '+350', f: '🇬🇮', cc: 'GI',
}, {
 n: 'Greenland', c: '+299', f: '🇬🇱', cc: 'GL',
}, {
 n: 'Guinea', c: '+224', f: '🇬🇳', cc: 'GN',
}, {
 n: 'Guadeloupe', c: '+590', f: '🇬🇵', cc: 'GP',
}, {
 n: 'Equatorial Guinea', c: '+240', f: '🇬🇶', cc: 'GQ',
}, {
 n: 'Greece', c: '+30', f: '🇬🇷', cc: 'GR',
}, {
 n: 'South Georgia and the South Sandwich Islands', c: '+500', f: '🇬🇸', cc: 'GS',
}, {
 n: 'Guatemala', c: '+502', f: '🇬🇹', cc: 'GT',
}, {
 n: 'Guam', c: '+1', f: '🇬🇺', cc: 'GU',
}, {
 n: 'Guinea-Bissau', c: '+245', f: '🇬🇼', cc: 'GW',
}, {
 n: 'Guyana', c: '+592', f: '🇬🇾', cc: 'GY',
}, {
 n: 'Hong Kong', c: '+852', f: '🇭🇰', cc: 'HK',
}, {
 n: 'Honduras', c: '+504', f: '🇭🇳', cc: 'HN',
}, {
 n: 'Croatia', c: '+385', f: '🇭🇷', cc: 'HR',
}, {
 n: 'Haiti', c: '+509', f: '🇭🇹', cc: 'HT',
}, {
 n: 'Hungary', c: '+36', f: '🇭🇺', cc: 'HU',
}, {
 n: 'Indonesia', c: '+62', f: '🇮🇩', cc: 'ID',
}, {
 n: 'Ireland', c: '+353', f: '🇮🇪', cc: 'IE',
}, {
 n: 'Israel', c: '+972', f: '🇮🇱', cc: 'IL',
}, {
 n: 'Isle of Man', c: '+44', f: '🇮🇲', cc: 'IM',
}, {
 n: 'India', c: '+91', f: '🇮🇳', cc: 'IN',
}, {
 n: 'Iraq', c: '+964', f: '🇮🇶', cc: 'IQ',
}, {
 n: 'Iran (Islamic Republic of)', c: '+98', f: '🇮🇷', cc: 'IR',
}, {
 n: 'Iceland', c: '+354', f: '🇮🇸', cc: 'IS',
}, {
 n: 'Italy', c: '+39', f: '🇮🇹', cc: 'IT',
}, {
 n: 'Jersey', c: '+44', f: '🇯🇪', cc: 'JE',
}, {
 n: 'Jamaica', c: '+876', f: '🇯🇲', cc: 'JM',
}, {
 n: 'Jordan', c: '+962', f: '🇯🇴', cc: 'JO',
}, {
 n: 'Japan', c: '+81', f: '🇯🇵', cc: 'JP',
}, {
 n: 'Kenya', c: '+254', f: '🇰🇪', cc: 'KE',
}, {
 n: 'Kyrgyzstan', c: '+996', f: '🇰🇬', cc: 'KG',
}, {
 n: 'Cambodia', c: '+855', f: '🇰🇭', cc: 'KH',
}, {
 n: 'North Korea', c: '+850', f: '🇰🇵', cc: 'KP',
}, {
 n: 'South Korea', c: '+82', f: '🇰🇷', cc: 'KR',
}, {
 n: 'Kiribati', c: '+686', f: '🇰🇮', cc: 'KI',
}, {
 n: 'Saint Kitts and Nevis', c: '+1869', f: '🇰🇳', cc: 'KN',
}, {
 n: 'Kuwait', c: '+965', f: '🇰🇼', cc: 'KW',
}, {
 n: 'Kazakhstan', c: '+7', f: '🇰🇿', cc: 'KZ',
}, {
 n: 'Lebanon', c: '+961', f: '🇱🇧', cc: 'LB',
}, {
 n: 'Saint Lucia', c: '+1758', f: '🇱🇨', cc: 'LC',
}, {
 n: 'Liechtenstein', c: '+423', f: '🇱🇮', cc: 'LI',
}, {
 n: 'Sri Lanka', c: '+94', f: '🇱🇰', cc: 'LK',
}, {
 n: 'Liberia', c: '+231', f: '🇱🇷', cc: 'LR',
}, {
 n: 'Lesotho', c: '+266', f: '🇱🇸', cc: 'LS',
}, {
 n: 'Lithuania', c: '+370', f: '🇱🇹', cc: 'LT',
}, {
 n: 'Luxembourg', c: '+352', f: '🇱🇺', cc: 'LU',
}, {
 n: 'Latvia', c: '+371', f: '🇱🇻', cc: 'LV',
}, {
 n: 'Libya', c: '+218', f: '🇱🇾', cc: 'LY',
}, {
 n: 'Morocco', c: '+212', f: '🇲🇦', cc: 'MA',
}, {
 n: 'Monaco', c: '+377', f: '🇲🇨', cc: 'MC',
}, {
 n: 'Montenegro', c: '+382', f: '🇲🇪', cc: 'ME',
}, {
 n: 'Saint Martin (French part)', c: '+590', f: '🇲🇫', cc: 'MF',
}, {
 n: 'Madagascar', c: '+261', f: '🇲🇬', cc: 'MG',
}, {
 n: 'Mali', c: '+223', f: '🇲🇱', cc: 'ML',
}, {
 n: 'Myanmar', c: '+95', f: '🇲🇲', cc: 'MM',
}, {
 n: 'Mongolia', c: '+976', f: '🇲🇳', cc: 'MN',
}, {
 n: 'Macao', c: '+853', f: '🇲🇴', cc: 'MO',
}, {
 n: 'Martinique', c: '+596', f: '🇲🇶', cc: 'MQ',
}, {
 n: 'Mauritania', c: '+222', f: '🇲🇷', cc: 'MR',
}, {
 n: 'Montserrat', c: '+1664', f: '🇲🇸', cc: 'MS',
}, {
 n: 'Malta', c: '+356', f: '🇲🇹', cc: 'MT',
}, {
 n: 'Mauritius', c: '+230', f: '🇲🇺', cc: 'MU',
}, {
 n: 'Maldives', c: '+960', f: '🇲🇻', cc: 'MV',
}, {
 n: 'Malawi', c: '+265', f: '🇲🇼', cc: 'MW',
}, {
 n: 'Mexico', c: '+52', f: '🇲🇽', cc: 'MX',
}, {
 n: 'Malaysia', c: '+60', f: '🇲🇾', cc: 'MY',
}, {
 n: 'Mozambique', c: '+258', f: '🇲🇿', cc: 'MZ',
}, {
 n: 'Namibia', c: '+264', f: '🇳🇦', cc: 'NA',
}, {
 n: 'New Caledonia', c: '+687', f: '🇳🇨', cc: 'NC',
}, {
 n: 'Norfolk Island', c: '+672', f: '🇳🇫', cc: 'NF',
}, {
 n: 'Nigeria', c: '+234', f: '🇳🇬', cc: 'NG',
}, {
 n: 'Nicaragua', c: '+505', f: '🇳🇮', cc: 'NI',
}, {
 n: 'Norway', c: '+47', f: '🇳🇴', cc: 'NO',
}, {
 n: 'Nepal', c: '+977', f: '🇳🇵', cc: 'NP',
}, {
 n: 'Nauru', c: '+674', f: '🇳🇷', cc: 'NR',
}, {
 n: 'Niue', c: '+683', f: '🇳🇺', cc: 'NU',
}, {
 n: 'New Zealand', c: '+64', f: '🇳🇿', cc: 'NZ',
}, {
 n: 'Oman', c: '+968', f: '🇴🇲', cc: 'OM',
}, {
 n: 'Panama', c: '+507', f: '🇵🇦', cc: 'PA',
}, {
 n: 'Peru', c: '+51', f: '🇵🇪', cc: 'PE',
}, {
 n: 'French Polynesia', c: '+689', f: '🇵🇫', cc: 'PF',
}, {
 n: 'Papua New Guinea', c: '+675', f: '🇵🇬', cc: 'PG',
}, {
 n: 'Pakistan', c: '+92', f: '🇵🇰', cc: 'PK',
}, {
 n: 'Poland', c: '+48', f: '🇵🇱', cc: 'PL',
}, {
 n: 'Saint Pierre and Miquelon', c: '+508', f: '🇵🇲', cc: 'PM',
}, {
 n: 'Pitcairn', c: '+64', f: '🇵🇳', cc: 'PN',
}, {
 n: 'Puerto Rico', c: '+1', f: '🇵🇷', cc: 'PR',
}, {
 n: 'Palestine, State of', c: '+970', f: '🇵🇸', cc: 'PS',
}, {
 n: 'Portugal', c: '+351', f: '🇵🇹', cc: 'PT',
}, {
 n: 'Palau', c: '+680', f: '🇵🇼', cc: 'PW',
}, {
 n: 'Paraguay', c: '+595', f: '🇵🇾', cc: 'PY',
}, {
 n: 'Qatar', c: '+974', f: '🇶🇦', cc: 'QA',
}, {
 n: 'Réunion', c: '+262', f: '🇷🇪', cc: 'RE',
}, {
 n: 'Romania', c: '+40', f: '🇷🇴', cc: 'RO',
}, {
 n: 'Serbia', c: '+381', f: '🇷🇸', cc: 'RS',
}, {
 n: 'Russia', c: '+7', f: '🇷🇺', cc: 'RU',
}, {
 n: 'Rwanda', c: '+250', f: '🇷🇼', cc: 'RW',
}, {
 n: 'Saudi Arabia', c: '+966', f: '🇸🇦', cc: 'SA',
}, {
 n: 'Solomon Islands', c: '+677', f: '🇸🇧', cc: 'SB',
}, {
 n: 'Seychelles', c: '+248', f: '🇸🇨', cc: 'SC',
}, {
 n: 'Sweden', c: '+46', f: '🇸🇪', cc: 'SE',
}, {
 n: 'Singapore', c: '+65', f: '🇸🇬', cc: 'SG',
}, {
 n: 'Saint Helena, Ascension and Tristan da Cunha', c: '+290', f: '🇸🇭', cc: 'SH',
}, {
 n: 'Slovenia', c: '+386', f: '🇸🇮', cc: 'SI',
}, {
 n: 'Svalbard and Jan Mayen', c: '+4779', f: '🇸🇯', cc: 'SJ',
}, {
 n: 'Slovakia', c: '+421', f: '🇸🇰', cc: 'SK',
}, {
 n: 'Sierra Leone', c: '+232', f: '🇸🇱', cc: 'SL',
}, {
 n: 'San Marino', c: '+378', f: '🇸🇲', cc: 'SM',
}, {
 n: 'Senegal', c: '+221', f: '🇸🇳', cc: 'SN',
}, {
 n: 'Somalia', c: '+252', f: '🇸🇴', cc: 'SO',
}, {
 n: 'Suriname', c: '+597', f: '🇸🇷', cc: 'SR',
}, {
 n: 'South Sudan', c: '+211', f: '🇸🇸', cc: 'SS',
}, {
 n: 'Sao Tome and Principe', c: '+239', f: '🇸🇹', cc: 'ST',
}, {
 n: 'El Salvador', c: '+503', f: '🇸🇻', cc: 'SV',
}, {
 n: 'Sint Maarten (Dutch part)', c: '+1721', f: '🇸🇽', cc: 'SX',
}, {
 n: 'Syrian Arab Republic', c: '+963', f: '🇸🇾', cc: 'SY',
}, {
 n: 'Chad', c: '+235', f: '🇹🇩', cc: 'TD',
}, {
 n: 'Togo', c: '+228', f: '🇹🇬', cc: 'TG',
}, {
 n: 'Thailand', c: '+66', f: '🇹🇭', cc: 'TH',
}, {
 n: 'Tajikistan', c: '+992', f: '🇹🇯', cc: 'TJ',
}, {
 n: 'Tokelau', c: '+690', f: '🇹🇰', cc: 'TK',
}, {
 n: 'Timor-Leste', c: '+670', f: '🇹🇱', cc: 'TL',
}, {
 n: 'Turkmenistan', c: '+993', f: '🇹🇲', cc: 'TM',
}, {
 n: 'Tunisia', c: '+216', f: '🇹🇳', cc: 'TN',
}, {
 n: 'Tonga', c: '+676', f: '🇹🇴', cc: 'TO',
}, {
 n: 'Turkey', c: '+90', f: '🇹🇷', cc: 'TR',
}, {
 n: 'Trinidad and Tobago', c: '+868', f: '🇹🇹', cc: 'TT',
}, {
 n: 'Tuvalu', c: '+688', f: '🇹🇻', cc: 'TV',
}, {
 n: 'United Republic of Tanzania', c: '+255', f: '🇹🇿', cc: 'TZ',
}, {
 n: 'Ukraine', c: '+380', f: '🇺🇦', cc: 'UA',
}, {
 n: 'Uganda', c: '+256', f: '🇺🇬', cc: 'UG',
}, {
 n: 'United States of America', c: '+1', f: '🇺🇸', cc: 'US',
}, {
 n: 'Uruguay', c: '+598', f: '🇺🇾', cc: 'UY',
}, {
 n: 'Uzbekistan', c: '+998', f: '🇺🇿', cc: 'UZ',
}, {
 n: 'Saint Vincent and the Grenadines', c: '+1784', f: '🇻🇨', cc: 'VC',
}, {
 n: 'Venezuela (Bolivarian Republic of)', c: '+58', f: '🇻🇪', cc: 'VE',
}, {
 n: 'Virgin Islands (British)', c: '+1284', f: '🇻🇬', cc: 'VG',
}, {
 n: 'Virgin Islands (U.S.)', c: '+1340', f: '🇻🇮', cc: 'VI',
}, {
 n: 'Vietnam', c: '+84', f: '🇻🇳', cc: 'VN',
}, {
 n: 'Vanuatu', c: '+678', f: '🇻🇺', cc: 'VU',
}, {
 n: 'Wallis and Futuna', c: '+681', f: '🇼🇫', cc: 'WF',
}, {
 n: 'Samoa', c: '+685', f: '🇼🇸', cc: 'WS',
}, {
 n: 'Yemen', c: '+967', f: '🇾🇪', cc: 'YE',
}, {
 n: 'Mayotte', c: '+262', f: '🇾🇹', cc: 'YT',
}, {
 n: 'South Africa', c: '+27', f: '🇿🇦', cc: 'ZA',
}, {
 n: 'Zambia', c: '+260', f: '🇿🇲', cc: 'ZM',
}, {
 n: 'Zimbabwe', c: '+263', f: '🇿🇼', cc: 'ZW',
}, {
 n: 'Eswatini', c: '+268', f: '🇸🇿', cc: 'SZ',
}, {
 n: 'North Macedonia', c: '+389', f: '🇲🇰', cc: 'MK',
}, {
 n: 'Philippines', c: '+63', f: '🇵🇭', cc: 'PH',
}, {
 n: 'Netherlands', c: '+31', f: '🇳🇱', cc: 'NL',
}, {
 n: 'United Arab Emirates', c: '+971', f: '🇦🇪', cc: 'AE',
}, {
 n: 'Republic of Moldova', c: '+373', f: '🇲🇩', cc: 'MD',
}, {
 n: 'Gambia', c: '+220', f: '🇬🇲', cc: 'GM',
}, {
 n: 'Dominican Republic', c: '+1', f: '🇩🇴', cc: 'DO',
}, {
 n: 'Sudan', c: '+249', f: '🇸🇩', cc: 'SD',
}, {
 n: 'Lao People\'s Democratic Republic', c: '+856', f: '🇱🇦', cc: 'LA',
}, {
 n: 'Taiwan, Province of China', c: '+886', f: '🇹🇼', cc: 'TW',
}, {
 n: 'Republic of the Congo', c: '+242', f: '🇨🇬', cc: 'CG',
}, {
 n: 'Czechia', c: '+420', f: '🇨🇿', cc: 'CZ',
}, {
 n: 'United Kingdom', c: '+44', f: '🇬🇧', cc: 'GB',
}, {
 n: 'Niger', c: '+227', f: '🇳🇪', cc: 'NE',
}, {
 n: 'Democratic Republic of the Congo', c: '+243', f: '🇨🇩', cc: 'CD',
}, {
 n: 'Commonwealth of The Bahamas', c: '+1 242', f: '🇧🇸', cc: 'BS',
}, {
 n: 'Cocos (Keeling) Islands', c: '+61 891', f: '🇨🇨', cc: 'CC',
}, {
 n: 'Central African Republic', c: '+236', f: '🇨🇫', cc: 'CF',
}, {
 n: 'Cook Islands', c: '+682', f: '🇨🇰', cc: 'CK',
}, {
 n: 'Falkland Islands', c: '+500', f: '🇫🇰', cc: 'FK',
}, {
 n: 'Faroe Islands', c: '+298', f: '🇫🇴', cc: 'FO',
}, {
 n: 'Territory of Heard Island and McDonald Islands', c: '+672', f: '🇭🇲', cc: 'HM',
}, {
 n: 'British Indian Ocean Territory', c: '+246', f: '🇮🇴', cc: 'IO',
}, {
 n: 'Comoros', c: '+269', f: '🇰🇲', cc: 'KM',
}, {
 n: 'Cayman Islands', c: '+1 345', f: '🇰🇾', cc: 'KY',
}, {
 n: 'Republic of the Marshall Islands', c: '+692', f: '🇲🇭', cc: 'MH',
}, {
 n: 'Commonwealth of the Northern Mariana Islands', c: '+1 670', f: '🇲🇵', cc: 'MP',
}, {
 n: 'Turks and Caicos Islands', c: '+1 649', f: '🇹🇨', cc: 'TC',
}, {
 n: 'French Southern and Antarctic Lands', c: '+672', f: '🇹🇫', cc: 'TF',
}, {
 n: 'United States Minor Outlying Islands', c: '+246', f: '🇺🇲', cc: 'UM',
}, {
 n: 'Holy See', c: '+39', f: '🇻🇦', cc: 'VA',
}];
